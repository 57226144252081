<template>
  <div></div>
</template>

<script>
import { createSocket, sendWSPush, closeWs } from "@/js/webSocket";
export default {
  data() {
    return {};
  },

  mounted() {
    this.wsConnect()
  },
  destroyed() {
    closeWs()
  },
  methods: {
    wsConnect() {
      createSocket();
      // 注册监听事件
      window.addEventListener("onmessageWS", this.getsocketData);
    },
    getsocketData(e) {
        // 创建接收消息函数
        let data = e && e.detail.data;
        if (data) {
          console.log(data);
          let json = JSON.parse(data);
          if (json.event == "open") {
            // 已经建立了连接
            this.dialogVisible = true;
            let msg = { event: "open_screen", to_user: this.userId };
            sendWSPush(msg);
          }
        }
      },
    handleClose() {
      //想服务器发送断开连接的信息
      let msg = { event: "stop_screen", to_user: this.userId };
      sendWSPush(msg);
      closeWs();
      this.dialogVisible = false;
      window.removeEventListener("onmessageWS", this.getsocketData);
    },
    screenSharing(userid) {
      this.userId = userid;
      this.wsConnect();
    },
  },
};
</script>

<style lang="less" scoped>
</style>